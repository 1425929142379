<template>
  <!-- TODO: Maybe it is better to have a button or a div. -->
  <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
  <div class="back-button" @click="goBack">
    <InlineSvg :src="require('../../assets/icons/icn_arrowback.svg')" class="icon" alt="Delete icon" />
    {{ text }}
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'BackButton',
  components: {
    InlineSvg
  },
  props: {
    routeName: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true,
    },
    preLeaveCheck: {
      type: Function,
      default: () => true,
      note: 'if this function returns true, leave, else dont'
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup(props) {
    const router = useRouter()
    function goBack() {
      if (props.preLeaveCheck() && props.routeName) {
        router.push({ name: props.routeName, params: props.params })
      } else {

      }
    }

    return {
      goBack
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.back-button {
  align-items: center;
  color: var(--blue_link);
  display: flex;
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  width: fit-content;

  &:hover{
    cursor: pointer;
  }

  .icon {
    height: auto;
    margin-right: rem(14);
    width: rem(20);

    :deep g path {
      fill: var(--blue_link);
    }
  }
}
</style>
